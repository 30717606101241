import React from "react";

import { useStoryblokRelations } from "~hooks/useStoryblokRelations";

import { transformStoryblokEntryApiToTag } from "~utils/transformers/transformStoryblokEntryApiToTag";
import { transformStoryblokEntryNodeToTag } from "~utils/transformers/transformStoryblokEntryNodeToTag";

import { Box } from "~components/Box";
import { TagLink } from "~components/Tag/TagLink";

import type { CardProps } from "~components/Card";
import type { TagLinkProps } from "~components/Tag/TagLink";
import type { StoryblokEntry } from "~types/storyblok.types";

export interface RelationsTagsProps {
  content?: Array<string | StoryblokEntry>;
  isAlphabetized?: boolean;
}

export function RelationsTags({
  content,
  isAlphabetized,
  ...rest
}: RelationsTagsProps) {
  /** Transform relations to tags */
  const tags: Array<CardProps> | null = useStoryblokRelations<TagLinkProps>({
    content,
    transformApiEntry: transformStoryblokEntryApiToTag,
    transformGraphQlNode: transformStoryblokEntryNodeToTag,
    isAlphabetized,
    storyblokRelationsNodeType: "relationsCardsNodes",
  });

  if (!Array.isArray(tags)) return null;

  return (
    <Box display="flex" gap="spacing1.5" flexWrap="wrap" {...rest}>
      {tags.map((tag) => {
        return <TagLink {...tag} />;
      })}
    </Box>
  );
}
