export function divideAllContentsIntoPages<T>(
  allItemsIds: T[],
  itemsPerPage: number
): T[][] {
  return allItemsIds.reduce<T[][]>(
    (accumulator, currentValue, currentIndex) => {
      const page = Math.floor(currentIndex / itemsPerPage);
      if (!accumulator[page]) {
        accumulator[page] = []; // start a new chunk
      }
      accumulator[page]?.push(currentValue);

      return accumulator;
    },
    []
  );
}
