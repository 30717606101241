import { countryToAlpha2 } from "country-to-iso";

/**
 * Get country code from full city+country job location, e.g.
 * - London, United Kingdom -> GB
 * - Milan, Italy -> IT
 */
export function getCountryCodeFromJobLocation(location: string) {
  const countryName = location.split(", ")[1]?.trim();

  return countryName && countryToAlpha2(countryName);
}
