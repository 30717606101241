import React, { forwardRef } from "react";

import { faBars } from "@fortawesome/sharp-regular-svg-icons/faBars";

import { IconFontAwesome } from "~components/IconFontAwesome";

import * as styles from "./styles.css";
import { navMenuButton } from "./styles.css";

import type { ButtonHTMLAttributes } from "react";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";

export interface NavMobileButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOpen: boolean;
  background: VariantBackgroundColorEnum;
}

export const NavMobileButton = forwardRef<
  HTMLButtonElement,
  NavMobileButtonProps
>(({ ...rest }, ref) => {
  return (
    <button {...rest} type="button" className={styles.buttonStyle} ref={ref}>
      <IconFontAwesome size="2xl" icon={faBars} className={navMenuButton} />
    </button>
  );
});
