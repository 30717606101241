import { getCountryCodeFromJobLocation } from "./getCountryCodeFromJobLocation";

import type { JobSearchCollapsibleProps } from "./JobSearchCollapsible";

export function getUniqueCountryCodesFromJobList(
  jobList: JobSearchCollapsibleProps["jobList"]
): Array<string> | undefined {
  return jobList?.reduce((accumulator: Array<string>, job) => {
    /** Transform location to iso code */
    const countryCode = getCountryCodeFromJobLocation(job.location);

    if (countryCode && !accumulator?.includes(countryCode)) {
      accumulator.push(countryCode);
    }

    return accumulator;
  }, []);
}
