import React from "react";

import {
  Indicator as RadixNavMenuIndicator,
  List as RadixNavMenuList,
  Root as RadixNavMenuRoot,
  Viewport as RadixNavMenuViewport,
} from "@radix-ui/react-navigation-menu";

import { NavDesktopCategory } from "./_NavDesktopCategory";
import * as styles from "./styles.css";

import type { NavCategoryProps } from "../Nav.types";
import type { NavigationMenuProps } from "@radix-ui/react-navigation-menu";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface NavDesktopProps extends NavigationMenuProps {
  navCategories?: Array<StoryblokBlok & Omit<NavCategoryProps, "color">>;
}

export function NavDesktop({ navCategories, ...rest }: NavDesktopProps) {
  const [activeMenuCategory, setActiveMenuCategory] = React.useState("");

  if (!Array.isArray(navCategories)) return null;

  return (
    <RadixNavMenuRoot
      orientation="horizontal"
      className={styles.menuRoot}
      value={activeMenuCategory}
      onValueChange={setActiveMenuCategory}
      {...rest}
    >
      <RadixNavMenuList className={styles.menuList}>
        {navCategories.map((navigationMenuItem) => {
          const isActive = navigationMenuItem._uid === activeMenuCategory;

          return (
            <NavDesktopCategory
              key={navigationMenuItem.title}
              {...navigationMenuItem}
              isActive={isActive}
            />
          );
        })}

        <RadixNavMenuIndicator className={styles.indicatorWrapper}>
          <div id="menuArrow" className={styles.arrow} />
        </RadixNavMenuIndicator>
      </RadixNavMenuList>

      <div className={styles.viewportPosition}>
        <RadixNavMenuViewport className={styles.viewport} />
      </div>
    </RadixNavMenuRoot>
  );
}
