import React, { useMemo } from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { Collapsible } from "~components/Collapsible";

import { JobSearchCollapsibleItem } from "./JobSearchCollapsibleItem";
import { JobSearchCollapsibleTrigger } from "./JobSearchCollapsibleTrigger";

import type { JobSearchHit } from ".";
import type { CollapsibleProps } from "~components/Collapsible";

export interface JobSearchCollapsibleProps
  extends Omit<CollapsibleProps, "children" | "triggerContentNode"> {
  jobList?: Array<JobSearchHit>;

  title: string;
}
export function JobSearchCollapsible({
  title,
  jobList,
  ...rest
}: JobSearchCollapsibleProps) {
  /** Memoised render of trigger content */
  const collapsibleTrigger = useMemo(() => {
    if (jobList) {
      return <JobSearchCollapsibleTrigger title={title} jobList={jobList} />;
    }

    return null;
  }, [jobList, title]);

  if (!checkHasLength(jobList)) return null;

  return (
    <Collapsible
      {...rest}
      triggerContentNode={collapsibleTrigger}
      className={variantBackgroundColor.background_white}
      borderRadius="sm"
      border="default"
      marginY="spacing2"
    >
      {jobList?.map((job) => {
        return <JobSearchCollapsibleItem job={job} key={job.objectID} />;
      })}
    </Collapsible>
  );
}
