import React, { useEffect, useMemo } from "react";

import groupBy from "lodash.groupby";
import { useHits } from "react-instantsearch";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import * as reactTransitionGroupListItem from "~styles/common/transition.list_item.css";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { Box } from "~components/Box";
import { Button } from "~components/Button";

import { JobSearchCollapsible } from "./JobSearchCollapsible";

import type { AlgoliaHit } from "instantsearch.js";
import type { StoryblokFieldLink } from "~types/storyblok.types";

const NO_MATCH_HEADING = "We couldn't find a match for that.";
const NO_MATCH_TEXT =
  "Maybe try changing your filters or search terms. You could also go back and see all open jobs.";
const NO_MATCH_BUTTON_TEXT = "Clear filters";

export interface JobSearchHit extends AlgoliaHit {
  link: StoryblokFieldLink;
  location: string;
  teamName: string;
  title: string;
}

export interface JobSearchResultsProps {
  /**
   * @deprecated `roleCountCallback` shouldn't be in use anymore, and should be removed.
   */
  roleCountCallback(...args: Array<unknown>): unknown;
}

export function JobSearchResults({ roleCountCallback }: JobSearchResultsProps) {
  const { hits } = useHits<JobSearchHit>();

  useEffect(() => {
    roleCountCallback(hits.length);
  }, [hits, roleCountCallback]);

  const jobsByTeamName = useMemo(() => {
    return groupBy(hits, "teamName");
  }, [hits]);

  const distinctTeamNames = jobsByTeamName && Object.keys(jobsByTeamName);

  if (!checkHasLength(distinctTeamNames)) {
    return (
      <Box>
        <Box textAppearance="h3">{NO_MATCH_HEADING}</Box>
        <p>{NO_MATCH_TEXT}</p>
        <Button name={NO_MATCH_BUTTON_TEXT} appearance="primary">
          {NO_MATCH_BUTTON_TEXT}
        </Button>
      </Box>
    );
  }

  return (
    <TransitionGroup component="div">
      {distinctTeamNames.map((teamName) => {
        return (
          <CSSTransition
            teamName={teamName}
            timeout={150}
            classNames={reactTransitionGroupListItem}
            appear
            unmountOnExit
          >
            <JobSearchCollapsible
              key={teamName}
              jobList={jobsByTeamName[teamName]}
              title={teamName}
            />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
}
