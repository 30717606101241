import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { responsiveGridStyles } from "~styles/common/global.grid.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { Banner } from "~components/Banner";
import { Box } from "~components/Box";

import { Footer } from "../Footer";
import { Header } from "../Header";
import { useLayoutStoryblokData } from "./use_page_layout_wrapper_storyblok_data";

import type { ReactNode } from "react";
import type { BoxProps } from "~components/Box";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { GlobalPageData } from "~types/global.types";
import type {
  CommonPageContext,
  PageTemplateProps,
} from "~types/storyblok.types";

export interface LayoutPageProps
  extends PageTemplateProps,
    Omit<BoxProps, "data"> {
  children: ReactNode | Array<ReactNode>;
  data: GlobalPageData;
  headerBackground?: VariantBackgroundColorEnum;
  pageContext: CommonPageContext;
  programmaticPageTitle?: string;
}

export function LayoutPage({
  children,
  data,
  headerBackground = "background_white",
  pageContext,
  location,
  ...rest
}: LayoutPageProps) {
  const { header, banner, footer, alternates } = useLayoutStoryblokData({
    data,
  });

  const { atomProps } = extractAtomsFromProps(rest, getSprinkles);

  const { lang } = pageContext;

  return (
    <Box className={clsx(responsiveGridStyles)} position="relative">
      <Header
        alternates={alternates}
        currentLanguage={lang}
        headerBackground={headerBackground}
        {...header}
      />
      {banner && <Banner location={location} {...banner} />}
      <Box as="main" {...atomProps}>
        {children}
      </Box>

      <Footer {...footer} />
    </Box>
  );
}
