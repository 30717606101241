import React from "react";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { StoryblokComponent } from "~components/StoryblokComponent";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { LayoutSection } from "../LayoutSection";

import type { StoryblokSectionProps } from "~types/storyblok.types";

export function StoryblokSection({ bloks, ...rest }: StoryblokSectionProps) {
  if (!bloks || !checkHasLength(bloks)) return null;

  return (
    <StoryblokEditable {...rest}>
      <LayoutSection {...rest}>
        {Array.isArray(bloks) &&
          bloks.map((blok) => {
            return <StoryblokComponent key={blok._uid} {...blok} />;
          })}
      </LayoutSection>
    </StoryblokEditable>
  );
}
