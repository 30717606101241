import React from "react";

import { Box } from "~components/Box";
import { VStack } from "~components/VStack";

import { JobSearchControls } from "./JobSearchControls";
import * as styles from "./styles.css";

export interface JobSearchHeroProps {
  roleCount: number;
  title: string;
}

export function JobSearchHero({ title, roleCount }: JobSearchHeroProps) {
  let roleCountString = `${roleCount} role`;
  if (roleCount !== 1) {
    roleCountString += "s";
  }

  return (
    <VStack
      as="header"
      gap="spacing6"
      width="gridSpan8"
      marginX="auto"
      paddingTop="spacing16"
      paddingBottom="spacing10"
    >
      <Box>
        <Box textAppearance="prefix_lg" textAlign="center">
          {roleCountString}
        </Box>
        <Box as="h1" textAlign="center" fontFamily="formula">
          {title}
        </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns={{
          desktop: 3,
          tablet: 1,
        }}
        gap="spacing1.5"
        alignItems="start"
        className={styles.jobInputWrapper}
      >
        <JobSearchControls />
      </Box>
    </VStack>
  );
}
