import React, { useCallback, useMemo, useState } from "react";

import { faAngleDown } from "@fortawesome/sharp-regular-svg-icons/faAngleDown";

import { AccordionBase } from "~components/AccordionBase";
import { AccordionBaseItem } from "~components/AccordionBase/AccordionBaseItem";
import { AccordionBaseItemContent } from "~components/AccordionBase/AccordionBaseItemContent";
import { AccordionBaseItemTrigger } from "~components/AccordionBase/AccordionBaseItemTrigger";
import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { NavCallOut } from "~components/NavCallOut";
import { NavListItem } from "~components/NavListItem";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { Dialog } from "../../Dialog";
import { NavMobileButton } from "./_NavMobileMenuButton";
import { flattenNavCategories } from "./_flattenNavCategories";
import * as styles from "./styles.css";

import type { NavCategoryProps } from "../Nav.types";
import type { StoryblokFieldLink } from "~types/storyblok.types";

export interface NavMobileProps {
  buttonLink?: StoryblokFieldLink;
  buttonText?: string;
  mainMenuTitle: string;
  navCategories?: Array<NavCategoryProps>;
  secondaryButtonLink?: StoryblokFieldLink;
  secondaryButtonText?: string;
}

/**
 * Main site navigation for mobile devices
 * - renders a dialog and a button to to open it
 * - flattens nested `navCategories` and renders them in an accordion,
 * - renders a fixed CTA button as footer of the dialog
 */
export function NavMobile({
  navCategories,
  buttonLink,
  buttonText,
  mainMenuTitle,
  secondaryButtonText,
  secondaryButtonLink,
}: NavMobileProps) {
  const [accordionValue, setAccordionValue] = useState("0");

  const flattenedCategories = useMemo(() => {
    return !!navCategories && flattenNavCategories(navCategories);
  }, [navCategories]);

  const FooterComponent = useCallback(() => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="spacing2"
        alignItems="center"
      >
        <ButtonLink
          link={buttonLink}
          color="ui_primary_base"
          appearance="primary"
          minWidth="100%"
          isCentered
          size="lg"
        >
          {buttonText}
        </ButtonLink>
        <ButtonLink
          link={secondaryButtonLink}
          appearance="secondary"
          minWidth="100%"
          isCentered
          size="lg"
          shouldHaveArrowIcon={false}
        >
          {secondaryButtonText}
        </ButtonLink>
      </Box>
    );
  }, [buttonLink, buttonText, secondaryButtonLink, secondaryButtonText]);

  return (
    <Dialog
      data-testid="nav-mobile"
      className={styles.container}
      dialogDescription=""
      dialogTitle={mainMenuTitle}
      // callbackOnOpenChange={setIsOpen}
      customButtonElement={NavMobileButton}
      FooterComponent={FooterComponent}
      isLogoVisible
      isTitleVisible={false}
    >
      <AccordionBase
        collapsible
        defaultValue="0"
        value={accordionValue}
        onValueChange={setAccordionValue}
      >
        {Array.isArray(flattenedCategories) &&
          flattenedCategories.map((navSubCategory, index) => {
            const isActive = accordionValue === index.toString();

            return (
              <AccordionBaseItem
                /** Data is static, can key by index */
                // eslint-disable-next-line react/no-array-index-key
                key={`${navSubCategory.title}-${index}`}
                index={index}
                className={styles.accordionItem}
              >
                <AccordionBaseItemTrigger className={styles.trigger}>
                  <div>{navSubCategory.title}</div>
                  <IconFontAwesome
                    icon={faAngleDown}
                    /** @todo investigate rotation for mobile nav menu */
                    // rotation={
                    //   index.toString() === accordionValue ? 180 : undefined
                    // }
                    size="1x"
                  />
                </AccordionBaseItemTrigger>

                <AccordionBaseItemContent
                  forceMount // allows SEO crawling for internal links
                  className={styles.getContentStyle({ isActive })}
                >
                  <Box marginTop="spacing1">
                    {Array.isArray(navSubCategory.items) &&
                      navSubCategory.items?.map((navSubCategoryItem) => {
                        return (
                          <StoryblokEditable
                            key={navSubCategoryItem.title}
                            {...navSubCategoryItem}
                          >
                            <NavListItem
                              {...navSubCategoryItem}
                              key={navSubCategoryItem.title}
                              paddingY="spacing1"
                            />
                          </StoryblokEditable>
                        );
                      })}
                    {navSubCategory.callOut && (
                      <NavCallOut {...navSubCategory.callOut} device="mobile" />
                    )}
                  </Box>
                </AccordionBaseItemContent>
              </AccordionBaseItem>
            );
          })}
      </AccordionBase>
    </Dialog>
  );
}
