import { animate, scroll } from "motion";

import { DESKTOP_BREAKPOINT } from "../../styles/global/css_vars_media_queries";

import type { Polygon } from "./types";
import type {
  MotionKeyframesDefinition,
  ScrollOptions,
  ValueKeyframesDefinition,
} from "motion";

const UI_SCROLL_FINAL_POSITION = "-50%";
const UI_OPACITY_ANIMATION = [0.95, 0];

export const animateLeftUi = (
  leftUiComponent: Element | null,
  animationOptions: ScrollOptions
) => {
  if (!leftUiComponent) return;

  let leftUiInitialLeft = "5%";
  if (window.innerWidth > DESKTOP_BREAKPOINT) {
    leftUiInitialLeft = "15%";
  }

  animate(
    leftUiComponent,
    {
      y: [0, -8, -10, -8, 0],
      rotateY: [0, 4, 6, 4, 0],
      z: ["-40px", "-35px", "-40px"],
    },
    {
      duration: 12,
      repeat: Infinity,
      easing: "ease-in-out",
    }
  );
  scroll(
    animate(leftUiComponent, {
      top: ["15%", UI_SCROLL_FINAL_POSITION],
      opacity: UI_OPACITY_ANIMATION,
      left: [leftUiInitialLeft, "0%"],
    }),
    animationOptions
  );
};

export const animateRightUi = (
  rightUiComponent: Element | null,
  animationOptions: ScrollOptions
) => {
  if (!rightUiComponent) return;

  let rightUiInitialLeft = "10%";
  if (window.innerWidth > DESKTOP_BREAKPOINT) {
    rightUiInitialLeft = "20%";
  }

  animate(
    rightUiComponent,
    {
      y: [0, -8, -10, -8, 0],
      rotateY: [-5, -8, -10, -8, -5],
      z: ["40px", "35px", "40px"],
    },
    {
      duration: 10,
      repeat: Infinity,
      easing: "ease-in-out",
    }
  );

  scroll(
    animate(rightUiComponent, {
      top: ["-5%", UI_SCROLL_FINAL_POSITION],
      opacity: UI_OPACITY_ANIMATION,
      right: [rightUiInitialLeft, "0%"],
    }),
    animationOptions
  );
};

export const animateStepperDetails = (
  stepperDetails: NodeListOf<Element> | null | never[],
  animationOptions: ScrollOptions
) => {
  stepperDetails?.forEach((element) => {
    if (!element) return;
    scroll(
      animate(element, {
        opacity: [0, 0, 0, 1],
      }),
      animationOptions
    );
  });
};

export const animateHeroBg = (
  heroBackground: HTMLElement | null,
  backgroundReady: boolean,
  portalKeyframes: Polygon[],
  animationOptions: ScrollOptions
) => {
  if (!heroBackground || !backgroundReady) return;

  scroll(
    animate(heroBackground, {
      clipPath: portalKeyframes as ValueKeyframesDefinition,
    }),
    animationOptions
  );
};

/**
 * Mask to clip the phone and stepper on the bottom
 */
export const animateHero = (
  hero: Element | null,
  containerKeyframes: Polygon[],
  animationOptions: ScrollOptions
) => {
  if (!hero) return;
  scroll(
    animate(hero, {
      clipPath: containerKeyframes as ValueKeyframesDefinition,
    } as MotionKeyframesDefinition),
    animationOptions
  );
};

export const animateHeroHeader = (
  heroHeader: Element | null,
  animationOptions: ScrollOptions
) => {
  if (!heroHeader) return;
  scroll(
    animate(heroHeader, {
      y: [0, -500],
      scaleX: [1, 0.8],
      opacity: [1, 0],
    }),
    animationOptions
  );
};
