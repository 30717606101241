import React from "react";

import { faAngleDown } from "@fortawesome/sharp-regular-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/sharp-regular-svg-icons/faAngleUp";
import { faGlobe } from "@fortawesome/sharp-regular-svg-icons/faGlobe";
import { faSearch } from "@fortawesome/sharp-regular-svg-icons/faSearch";

import { useJobSearchContext } from "~context/JobSearchContext";

import { AlgoliaSearchInput } from "../../AlgoliaSearch/AlgoliaInput";
import { AlgoliaRefinementMultiSelect } from "../../AlgoliaSearch/SelectAlgoliaRefinement.tsx";

/** Job search controls */

const SEARCH_PLACEHOLDER_STRING = "Search...";
const LOCATION_PLACEHOLDER_STRING = "Location";
const DEPARTMENT_PLACEHOLDER_STRING = "Department";

export function JobSearchControls() {
  const {
    departmentRefinementListProps,
    officeRefinementListProps,
    clearDepartmentRefinementList,
    clearOfficeRefinementList,
  } = useJobSearchContext();

  return (
    <>
      <AlgoliaSearchInput
        iconLeft={faSearch}
        id="job-search"
        // marginBottom="spacing1"
        name="Job search"
        placeholder={SEARCH_PLACEHOLDER_STRING}
        size="lg"
      />
      {officeRefinementListProps && (
        <AlgoliaRefinementMultiSelect
          {...officeRefinementListProps}
          clearRefinements={clearOfficeRefinementList}
          id="location"
          name={LOCATION_PLACEHOLDER_STRING}
          placeholder={LOCATION_PLACEHOLDER_STRING}
          iconLeft={faGlobe}
          size="lg"
          iconRightClosed={faAngleDown}
          iconRightOpened={faAngleUp}
        />
      )}
      {departmentRefinementListProps && (
        <AlgoliaRefinementMultiSelect
          {...departmentRefinementListProps}
          clearRefinements={clearDepartmentRefinementList}
          name={DEPARTMENT_PLACEHOLDER_STRING}
          placeholder={DEPARTMENT_PLACEHOLDER_STRING}
          id="teamName"
          size="lg"
          iconRightClosed={faAngleDown}
        />
      )}
    </>
  );
}
