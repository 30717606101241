import React from "react";

import { Content as RadixNavMenuContent } from "@radix-ui/react-navigation-menu";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { Grid } from "~components/Grid";
import { NavCallOut } from "~components/NavCallOut";
import { NavListItem } from "~components/NavListItem";
import { StoryblokEditable } from "~components/StoryblokEditable";

import * as styles from "./styles.css";

import type { NavSubCategoryProps } from "../Nav.types";
import type { NavigationMenuContentProps } from "@radix-ui/react-navigation-menu";
import type { NavCallOutProps } from "~components/NavCallOut";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokFieldResponsiveValue } from "~types/storyblok.types";

export interface NavDesktopSubCategoryProps extends NavigationMenuContentProps {
  gridTemplateColumns?:
    | StoryblokFieldResponsiveValue
    | GetSprinklesArgs["gridTemplateColumns"];
  navSubCategories: Array<NavSubCategoryProps>;
  navCallOut?: NavCallOutProps;
  isActive: boolean;
}

/**
 * Desktop nav "category" content, comprised of trigger and
 * content to render into viewport when trigger is hovered.
 */
export function NavDesktopSubCategory({
  navSubCategories,
  navCallOut,
  isActive,
  ...rest
}: NavDesktopSubCategoryProps) {
  /** Clean output of responsive cms field, discarding keys that would break vanilla extract */
  const menuContentClassNames = clsx(
    styles.getMenuContentStyle({ isActive }),
    getSprinkles({})
  );

  if (!Array.isArray(navSubCategories)) return null;

  return (
    <RadixNavMenuContent
      forceMount // allows SEO crawling for internal links
      className={menuContentClassNames}
      {...rest}
    >
      {navSubCategories.map((subCategory) => {
        return (
          <Box
            id={`subcategory_${subCategory.title}`}
            key={subCategory._uid}
            maxWidth="gridSpan6"
            paddingX="spacing3"
            marginY="spacing3"
            rowGap="spacing2"
          >
            <Box textAppearance="prefix_md" marginBottom="spacing1.5">
              {subCategory.title}
            </Box>

            <Grid gridTemplateColumns={2} rowGap="spacing2">
              {Array.isArray(subCategory.items) &&
                subCategory.items?.map((item) => {
                  return (
                    <StoryblokEditable key={item.title} {...item}>
                      <NavListItem {...item} key={item.title} />
                    </StoryblokEditable>
                  );
                })}
            </Grid>
          </Box>
        );
      })}
      {navCallOut && <NavCallOut {...navCallOut} device="desktop" />}
    </RadixNavMenuContent>
  );
}
