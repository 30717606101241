import { PORTAL_POLYGONS } from "./paths";

import type {
  Polygon,
  PolygonRatio,
  PolygonResponsiveRatiosInput,
  PolygonResponsiveRatiosOutput,
  SizeArray,
} from "./types";

export const enum PolygonSizeOnWindowPercentageTypes {
  NUMBER = "number",
  ARRAY = "array",
  OBJECT = "object",
}
const enum PORTAL_BREAKPOINTS {
  DESKTOP = 769, // 768+ px
  TABLET = 481, // 480 - 768 px
  MOBILE = 0, // up to 480 px
}

const POLYGON_PREFIX = "polygon(";
const POLYGON_SUFFIX = ")";
const PIXEL_SUFFIX = "px";
const PERCENTAGE_SUFFIX = "%";

export const PORTAL_CONFIG: PORTAL_CONFIG_TYPE = {
  START: {
    polygon: PORTAL_POLYGONS.start,
    unitMeasure: "px",
    size: {
      mobile: 150,
      tablet: 90,
    },
  },
  MIDDLE: {
    polygon: PORTAL_POLYGONS.middle,
    unitMeasure: "%",
    size: {
      mobile: 150,
      tablet: 98,
      desktop: 90,
    },
  },
  END: {
    polygon: PORTAL_POLYGONS.end,
    unitMeasure: "px",
    size: {
      mobile: [115, 150],
      tablet: [115, 130],
      desktop: [115, 150],
    },
  },
  SMOOTH_POINTS: 5,
};

export type CLIP_PATH_CONFIG_TYPE = {
  polygon: Polygon;
  size: PolygonResponsiveRatiosInput;
  unitMeasure: "px" | "%";
};

export type PORTAL_CONFIG_TYPE = {
  START: CLIP_PATH_CONFIG_TYPE;
  MIDDLE: CLIP_PATH_CONFIG_TYPE;
  END: CLIP_PATH_CONFIG_TYPE;
  SMOOTH_POINTS: number;
};

/**
 * Receives a polygon string (e.g. polygon(0px 0px, 100px 0px, 100px 100px, 0px 100px))
 * and returns an array of points and the unit measure (e.g. [["0", "0"], ["100", "0"], ["100", "100"], ["0", "100"]], "px")
 * getPolygonString() does the opposite
 * @returns polygon points and unit measure
 */
const getPointsFromPolygon = (polygon: Polygon): [number[][], string] => {
  let unitMeasure = "";

  if (!polygon)
    throw new Error(
      "Impossible to get points from polygon: polygon is undefined"
    );

  const polygonPoints = polygon
    .replace(POLYGON_PREFIX, "")
    .replace(POLYGON_SUFFIX, "");

  if (polygonPoints.includes(PIXEL_SUFFIX)) {
    unitMeasure = PIXEL_SUFFIX;
  } else if (polygonPoints.includes(PERCENTAGE_SUFFIX)) {
    unitMeasure = PERCENTAGE_SUFFIX;
  } else {
    throw new Error(
      `Impossible to get points from polygon: unit measure is unknown: ${polygonPoints}`
    );
  }

  const cleanPolygon = polygonPoints.replace(unitMeasure, "");

  const points = cleanPolygon.split(", ").map((point) => {
    return point.split(" ").map((xy) => {
      return Number(
        xy.replace(PIXEL_SUFFIX, "").replace(PERCENTAGE_SUFFIX, "")
      );
    });
  });

  return [points, unitMeasure];
};

/**
 * Receives an array of points (e.g. [[0, 0], [100, 0], [100, 100], [0, 100]]) and a unit measure (e.g. "px")
 * and returns a polygon string (e.g. polygon(0px 0px, 100px 0px, 100px 100px, 0px 100px))
 * getPointsFromPolygon() does the opposite
 */
const getPolygonString = (points: number[][], suffix: string): Polygon => {
  return `${POLYGON_PREFIX}${points
    .map(([x, y]) => {
      return `${x}${suffix} ${y}${suffix}`;
    })
    .join(", ")}${POLYGON_SUFFIX}`;
};

/**
 * Creates a squared, rectangular or trapezoid clip path.
 * `top` and `bottom` are the X coordinates of the straight lines of the rectangle/trapezoid
 * `topX` and `bottomX` are lengths of the rectangle's/trapezoid's top and bottom lines
 * e.g. top = 0, topX = 100, bottom = 100, bottomX = 100 creates a square if the unit measure is "px", or a mask that shows all of its content if the unit measure is "%"
 * e.g. top = 0, topX = 100, bottom = 50, bottomX = 100 creates a rectangle with 50px height and 100px width if the unit measure is "px", or a mask that shows the upper half of its content if the unit measure is "%"
 * e.g. top = 0, topX = 50, bottom = 100, bottomX = 100 creates a trapezoid with the upper line half the size of the bottom line if the unit measure is "px", or a trapezoid with the upper line 50% of the container's width if the unit measure is "%"
 *
 * `unitMeasure` can be "px" or "%"
 * top and bottom represent the hi
 */
export const getClipPath = ({
  top,
  topX,
  bottom,
  bottomX,
  unitMeasure,
}: {
  top: number;
  topX: number;
  bottom: number;
  bottomX: number;
  unitMeasure: "px" | "%";
}) => {
  let points: number[][] = [];
  if (unitMeasure === "%") {
    points = [
      [topX, top],
      [100 - topX, top],
      [bottomX, bottom],
      [100 - bottomX, bottom],
    ];
  }
  if (unitMeasure === "px") {
    points = [
      [topX, top],
      [window.innerWidth - topX, top],
      [bottomX, bottom],
      [window.innerWidth - bottomX, bottom],
    ];
  }

  return getPolygonString(points, unitMeasure);
};

/**
 * Returns the top, bottom, left and right coordinates of a polygon
 */
export function getExtremes(polygon: Polygon): {
  top: number;
  bottom: number;
  left: number;
  right: number;
} {
  if (!polygon) throw new Error("polygon is undefined");

  const [points] = getPointsFromPolygon(polygon);

  let right = 0;
  let left = 9999;
  let bottom = 0;
  let top = 9999;

  points.forEach(([x, y]) => {
    if (right === undefined || right < Number(x)) {
      right = Number(x);
    }
    if (left === undefined || left > Number(x)) {
      left = Number(x);
    }
    if (bottom === undefined || bottom < Number(y)) {
      bottom = Number(y);
    }
    if (top === undefined || top > Number(y)) {
      top = Number(y);
    }
  });

  return { right, left, bottom, top };
}

/**
 * Centers a polygon in a background of a given size
 * `unitMeasure` can be "px" or "%" and represents the unit measure of the returned polygon - it can be different from the unitMeasure of the input polygon
 */
export const centerPolygon = (
  polygon: Polygon,
  bgSize: SizeArray,
  unitMeasure: string = "px"
) => {
  if (bgSize[0] === undefined || bgSize[1] === undefined)
    throw new Error("bgSize is undefined");
  const [points] = getPointsFromPolygon(polygon);

  const xCoordinates = points.map((point) => {
    return point[0]!;
  });
  const yCoordinates = points.map((point) => {
    return point[1]!;
  });

  const minX = Math.min(...xCoordinates);
  const maxX = Math.max(...xCoordinates);
  const minY = Math.min(...yCoordinates);
  const maxY = Math.max(...yCoordinates);

  const shapeWidth = maxX - minX;
  const shapeHeight = maxY - minY;
  const bgHeight = bgSize[1];
  if (bgHeight === null) throw new Error("bgHeight is null");
  const offsetX = (bgSize[0] - shapeWidth) / 2 - minX;
  const offsetY = (bgHeight - shapeHeight) / 2 - minY;

  const centeredPoints = points.map((point) => {
    const [oldX, oldY] = point;
    const newX = oldX! + offsetX;
    const newY = oldY! + offsetY;

    return [newX, newY];
  });

  return getPolygonString(centeredPoints, unitMeasure);
};

/**
 * Scales a polygon by a given percentage
 * `unitMeasure` can be "px" or "%" and represents the unit measure of the returned polygon - it can be different from the unitMeasure of the input polygon
 * `scalePercentage` represents the desired size of the polygon, in px or %
 * e.g. [200, 100] with unitMeasure "px" resizes the polygon to 200px width and 100px height
 */
export const resizePolygon = (
  polygon: Polygon,
  unitMeasure: string,
  scalePercentage: [number, number]
) => {
  if (!polygon)
    throw new Error("Impossible to scale polygon: polygon is undefined");

  const scaleFactorX = scalePercentage[0] / 100;
  const scaleFactorY = scalePercentage[1] / 100;

  const [points] = getPointsFromPolygon(polygon);

  const pointsScaled = points.map(([x, y]) => {
    return [x! * scaleFactorX, y! * scaleFactorY];
  });

  return getPolygonString(pointsScaled, unitMeasure);
};

const getLinearTerm = (
  prev: number | undefined,
  end: number | undefined,
  t: number
) => {
  if (prev === undefined || end === undefined) {
    return 0;
  }

  return (end - prev) * t;
};

const getQuadraticTerm = (
  prev: number | undefined,
  start: number | undefined,
  end: number | undefined,
  next: number | undefined,
  t: number
) => {
  if (
    prev === undefined ||
    start === undefined ||
    end === undefined ||
    next === undefined
  ) {
    return 0;
  }
  const interpolationFactorSquared = t ** 2;
  const prevWeighted = 2 * prev;
  const startWeighted = 5 * start;
  const endWeighted = 4 * end;

  return (
    (prevWeighted - startWeighted + endWeighted - next) *
    interpolationFactorSquared
  );
};

const getCubicTerm = (
  prev: number | undefined,
  start: number | undefined,
  end: number | undefined,
  next: number | undefined,
  t: number
) => {
  if (
    prev === undefined ||
    start === undefined ||
    end === undefined ||
    next === undefined
  )
    return 0;
  const interpolationFactorCubed = t ** 3;
  const startWeighted = 3 * start;
  const endWeighted = 3 * end;

  return (
    (-prev + startWeighted - endWeighted + next) * interpolationFactorCubed
  );
};

const getStartPointContribution = (start: number | undefined) => {
  if (start === undefined) return 0;

  return 2 * start;
};

/**
 * Adds points between two control points (start and end) to create a smooth curve, it requires two additional control points (prev and next) to calculate the curve
 * Implementation of the __Catmull-Rom spline__ math formula
 * Interpolates a point between two control points using a smooth curve
 */
function interpolatePoints(
  prevPoint: number[],
  startPoint: number[],
  endPoint: number[],
  nextPoint: number[],
  interpolationFactor: number
): number[] {
  const axes = [0, 1];

  return axes.map((axis) => {
    const linearContribution = getLinearTerm(
      prevPoint[axis],
      endPoint[axis],
      interpolationFactor
    );
    const quadraticContribution = getQuadraticTerm(
      prevPoint[axis],
      startPoint[axis],
      endPoint[axis],
      nextPoint[axis],
      interpolationFactor
    );
    const cubicContribution = getCubicTerm(
      prevPoint[axis],
      startPoint[axis],
      endPoint[axis],
      nextPoint[axis],
      interpolationFactor
    );
    const startPointContribution = getStartPointContribution(startPoint[axis]);

    return (
      0.5 *
      (startPointContribution +
        linearContribution +
        quadraticContribution +
        cubicContribution)
    );
  });
}

/**
 * Calculates the distance between two points
 */
function getDistance(startPoint: number[], endPoint: number[]): number {
  if (
    startPoint[0] === undefined ||
    startPoint[1] === undefined ||
    endPoint[0] === undefined ||
    endPoint[1] === undefined
  )
    throw new Error(
      "Impossible to compute distance: One of the points is undefined"
    );

  return Math.sqrt(
    (endPoint[0] - startPoint[0]) ** 2 + (endPoint[1] - startPoint[1]) ** 2
  );
}

/**
 * Calculates the average distance between points in a polygon
 */
function calculateAverageDistance(points: number[][]): number {
  let totalDistance = 0;
  for (let i = 0; i < points.length - 1; i += 1) {
    const startPoint = points[i];
    const endPoint = points[i + 1];
    if (startPoint === undefined || endPoint === undefined)
      throw new Error(
        `Impossible to compute average distance: One of the points is undefined: ${{
          startPoint,
          endPoint,
        }}`
      );
    totalDistance += getDistance(startPoint, endPoint);
  }

  return totalDistance / (points.length - 1);
}

/**
 * Generates a smooth path from a polygon
 * `numPoints` is the number of points to interpolate between each pair of points
 * `unitMeasure` can be "px" or "%" and represents the unit measure of the returned polygon
 */
export function generateSmoothPath(
  polygon: Polygon,
  numPoints: number,
  unitMeasure = "px"
): Polygon {
  if (polygon === undefined)
    throw new Error("Impossible to generate smooth path: polygon is undefined");
  const [points] = getPointsFromPolygon(polygon);

  const smoothPath: number[][] = [];

  // Calculate the average distance between points
  const averageDistance = calculateAverageDistance(points);
  // Threshold to avoid adding points that would distort the shape - we only add points when the distance between points is less than 1.5 times the average distance, meaning that they are close enough to create a curve
  const distanceThreshold = averageDistance * 1.5;

  for (let i = 0; i < points.length - 3; i += 1) {
    const prevPoint = points[i];
    const startPoint = points[i + 1];
    const endPoint = points[i + 2];
    const nextPoint = points[i + 3];
    if (
      prevPoint === undefined ||
      startPoint === undefined ||
      endPoint === undefined ||
      nextPoint === undefined
    )
      throw new Error(
        "Impossible to generate smooth path: One of the points is undefined"
      );

    // Prevent adding points that would distort the shape
    const distanceP0P1 = getDistance(prevPoint, startPoint);
    const distanceP1P2 = getDistance(startPoint, endPoint);
    const distanceP2P3 = getDistance(endPoint, nextPoint);
    if (
      distanceP0P1 < distanceThreshold &&
      distanceP1P2 < distanceThreshold &&
      distanceP2P3 < distanceThreshold
    ) {
      // Prepare the points for interpolation as part of the Catmull-Rom spline math formula
      for (let j = 0; j <= numPoints; j += 1) {
        const interpolationFactor = j / numPoints;
        if (
          prevPoint === undefined ||
          startPoint === undefined ||
          endPoint === undefined ||
          nextPoint === undefined
        )
          throw new Error(
            `Impossible to call catmullRomSpline function: One of the points is undefined: prevPoint: ${prevPoint}; startPoint: ${startPoint}; endPoint: ${endPoint}; nextPoint: ${nextPoint}`
          );

        const newPoint = interpolatePoints(
          prevPoint,
          startPoint,
          endPoint,
          nextPoint,
          interpolationFactor
        );

        // Add the new point to the smooth path
        smoothPath.push(newPoint);
      }
    }
  }

  return getPolygonString(smoothPath, unitMeasure);
}

/**
 * Ensures that the polygons have the same number of points to avoid issues when animating them
 * Takes an array of polygons and returns an array of polygons with the same number of points
 * It adds points to the polygons with fewer points to make them have the same number of points as the polygon with the most points
 * e.g. if 3 polygons are passed, one with 4 points, one with 5 points and one with 6 points, the function will return 3 polygons with 6 points each
 */
export function equalizePolygonPoints<T extends Polygon[]>(polygons: T): T {
  const polygonsPoints: number[][][] = [];
  const polygonsUnitMeasures: string[] = [];

  polygons.forEach((polygon, i) => {
    if (polygon === undefined)
      throw new Error(
        `Error in equalizePolygonPoints: polygon ${i} is undefined`
      );

    const [points, unitMeasure] = getPointsFromPolygon(polygon);
    polygonsPoints.push(points);
    polygonsUnitMeasures.push(unitMeasure);
  });

  const maxPoints = Math.max(
    ...polygonsPoints.map((points) => {
      return points.length;
    })
  );

  const newPolygons = polygonsPoints.map((polygonPoints) => {
    if (polygonPoints.length >= maxPoints) return polygonPoints;

    const delta = maxPoints - polygonPoints.length;
    const newPoints: number[][] = [];

    const point = polygonPoints[0];
    if (!point) throw new Error("point is undefined");

    for (let index = 0; index < delta; index += 1) {
      newPoints.push(point);
    }
    newPoints.push(...polygonPoints);

    return newPoints;
  });

  return newPolygons.map((polygon, i) => {
    const unitMeasure = polygonsUnitMeasures[i];
    if (unitMeasure === undefined) throw new Error("unit measure is undefined");

    return getPolygonString(polygon, unitMeasure);
  }) as T;
}

/**
 * Identifies what type of polygon size is being used
 */
const getPolygonSizeType = (
  polygonSize: PolygonResponsiveRatiosInput | PolygonRatio
) => {
  let polygonSizeOnWindowType: PolygonSizeOnWindowPercentageTypes;
  switch (true) {
    case typeof polygonSize === "number":
      polygonSizeOnWindowType = PolygonSizeOnWindowPercentageTypes.NUMBER;
      break;
    case Array.isArray(polygonSize):
      polygonSizeOnWindowType = PolygonSizeOnWindowPercentageTypes.ARRAY;
      break;
    default:
      polygonSizeOnWindowType = PolygonSizeOnWindowPercentageTypes.OBJECT;
      break;
  }

  return polygonSizeOnWindowType;
};

/**
 * Takes a PolygonRatio, which can be a number or an array, and returns an array
 * e.g. 100 -> [100, null]
 * e.g. [100, 200] -> [100, 200]
 */
const getArrayFromPolygonRatio = (
  ratio: PolygonRatio | undefined
): SizeArray | undefined => {
  if (typeof ratio === "undefined") return ratio;

  return typeof ratio === "number" ? [ratio, null] : ratio;
};

/**
 * Takes a PolygonResponsiveRatiosInput, which can have three keys: mobile, tablet and desktop, and returns an object with the same keys, but with the values as arrays
 * e.g. { mobile: 100, tablet: 200, desktop: 300 } -> { mobile: [100, null], tablet: [200, null], desktop: [300, null] }
 * e.g. { mobile: [100, 200], tablet: [200, 300], desktop: [300, 400] } -> { mobile: [100, 200], tablet: [200, 300], desktop: [300, 400] }
 */
const getResponsiveSizes = (
  polygonSize: PolygonResponsiveRatiosInput | PolygonRatio
) => {
  const polygonSizeType = getPolygonSizeType(polygonSize);

  let responsiveSizes: PolygonResponsiveRatiosOutput;

  switch (polygonSizeType) {
    case PolygonSizeOnWindowPercentageTypes.NUMBER: {
      const uniqueSize = polygonSize as number;
      responsiveSizes = {
        mobile: getArrayFromPolygonRatio(uniqueSize),
        tablet: getArrayFromPolygonRatio(uniqueSize),
        desktop: getArrayFromPolygonRatio(uniqueSize),
      };
      break;
    }
    case PolygonSizeOnWindowPercentageTypes.ARRAY: {
      const [width, height] = polygonSize as SizeArray;
      responsiveSizes = {
        mobile: [width, height],
        tablet: [width, height],
        desktop: [width, height],
      };
      break;
    }
    default: {
      const { mobile, tablet, desktop } =
        polygonSize as PolygonResponsiveRatiosInput;

      const newMobile = getArrayFromPolygonRatio(mobile);
      const newTablet = getArrayFromPolygonRatio(tablet) || newMobile;
      const newDesktop = getArrayFromPolygonRatio(desktop) || newTablet;

      responsiveSizes = {
        mobile: newMobile,
        tablet: newTablet,
        desktop: newDesktop,
      };
      break;
    }
  }

  return responsiveSizes;
};

/**
 * Returns the size that should be used based on the current window width and the portal breakpoints
 */
const getCurrentSize = (
  { mobile, tablet, desktop }: PolygonResponsiveRatiosOutput,
  width: number
) => {
  let currentSize: PolygonRatio | undefined;

  switch (true) {
    case width >= PORTAL_BREAKPOINTS.DESKTOP:
      if (desktop === undefined)
        throw new Error("desktop responsive size is undefined");
      currentSize = desktop;
      break;
    case width >= PORTAL_BREAKPOINTS.TABLET:
      if (tablet === undefined)
        throw new Error("tablet responsive size is undefined");
      currentSize = tablet;
      break;
    default:
      if (mobile === undefined)
        throw new Error("mobile responsive size is undefined");
      currentSize = mobile;
      break;
  }

  return currentSize;
};

/**
 * Takes a polygon, its desired size based on the window width and the window size, and returns a new polygon with the desired size
 * __Works only with fixed size polygons__
 */
export const getResponsivePolygon = (
  polygon: Polygon,
  polygonSizeOnWindowPercentage: PolygonResponsiveRatiosInput | PolygonRatio,
  pageSize: SizeArray
) => {
  const { mobile, tablet, desktop } = getResponsiveSizes(
    polygonSizeOnWindowPercentage
  );

  const [containerWidth, containerHeight] = pageSize;

  const [currentX, currentY] = getCurrentSize(
    { mobile, tablet, desktop },
    containerWidth
  );

  const { bottom, top, right, left } = getExtremes(polygon);
  const shapeOriginalWidth = right - left;
  const shapeOriginalHeight = bottom - top;

  const newPolygonWidth =
    ((containerWidth * currentX) / 100 / shapeOriginalWidth) * 100;

  if (currentY && containerHeight === undefined)
    throw new Error("containerHeight is undefined");
  const newPolygonHeight = currentY
    ? ((containerHeight! * currentY) / 100 / shapeOriginalHeight) * 100
    : newPolygonWidth;

  return resizePolygon(polygon, "px", [newPolygonWidth, newPolygonHeight]);
};

type AdaptPolygonParams = {
  polygon: Polygon;
  smoothPoints: number;
  unitMeasure: "%" | "px";
  size: PolygonResponsiveRatiosInput;
  pageSize: SizeArray;
  containerSize: SizeArray;
};

/**
 * Wrapper function that adapts a polygon to the page based on the given parameters
 */
export const adaptPolygonToPage = ({
  polygon,
  smoothPoints,
  unitMeasure,
  size,
  pageSize,
  containerSize,
}: AdaptPolygonParams) => {
  let adaptedPolygon = polygon;

  adaptedPolygon = generateSmoothPath(
    adaptedPolygon,
    smoothPoints,
    unitMeasure
  );

  adaptedPolygon = getResponsivePolygon(adaptedPolygon, size, pageSize);

  adaptedPolygon = centerPolygon(adaptedPolygon, containerSize, "px"); // always use px to center?

  return adaptedPolygon;
};

export type AdaptPolygonsToPageParams = {
  polygons: {
    polygon: AdaptPolygonParams["polygon"];
    unitMeasure: AdaptPolygonParams["unitMeasure"];
    size: AdaptPolygonParams["size"];
  }[];
  smoothPoints: AdaptPolygonParams["smoothPoints"];
  pageSize: AdaptPolygonParams["pageSize"];
  containerSize: AdaptPolygonParams["containerSize"];
};

/**
 * Wrapper function that adapts an array of polygons to the page based on the given parameters
 * and ensures that all polygons have the same number of points
 */
export const adaptPolygonsToPage = ({
  polygons,
  ...rest
}: AdaptPolygonsToPageParams): Polygon[] => {
  const adaptedPolygons = polygons.map((polygon) => {
    return adaptPolygonToPage({
      ...polygon,
      ...rest,
    });
  });

  const equalizedPolygons = equalizePolygonPoints(adaptedPolygons);

  for (let i = 0; i < polygons.length; i += 1) {
    if (equalizedPolygons[i] === undefined) {
      throw new Error(
        `Error in adaptPolygonsToPage: polygon ${i} is undefined`
      );
    }
  }

  return equalizedPolygons;
};

/**
 * Checks if the current window size is different from the previous size by a certain threshold,
 * to avoid unnecessary resizing of the polygons
 */
export const shouldResize = (
  windowSize: SizeArray,
  pageSize: SizeArray,
  threshold: number
) => {
  const horizontalResize = Math.abs(windowSize[0] - pageSize[0]);
  const verticalResize =
    windowSize[1] && pageSize[1] ? Math.abs(windowSize[1] - pageSize[1]) : 0;

  return horizontalResize > threshold || verticalResize > threshold;
};

export type ContainerKeyframesParams = {
  portalKeyframes: Polygon[];
  phoneOffset: number | undefined;
  heroBackgroundOffset: number | undefined;
  scrollOffset: number;
};
/**
 * Wrapper function that generates the start, middle and end clip path for the container
 */
export const getContainerKeyframes = ({
  portalKeyframes,
  phoneOffset,
  heroBackgroundOffset,
}: ContainerKeyframesParams) => {
  const { innerWidth } = window;

  const [clipPathStart, clipPathMiddle, clipPathEnd] = portalKeyframes;
  if (
    !clipPathStart ||
    !clipPathMiddle ||
    !clipPathEnd ||
    phoneOffset === undefined ||
    heroBackgroundOffset === undefined
  )
    return [];

  const { bottom: bottomStart } = getExtremes(clipPathStart);
  const { bottom: bottomMiddle } = getExtremes(clipPathMiddle);
  const { bottom: bottomEnd } = getExtremes(clipPathEnd);
  const clipPathContainerOffset = phoneOffset - heroBackgroundOffset;

  const clipPathContainerStart = getClipPath({
    top: -1000,
    topX: 0,
    bottom: bottomStart - clipPathContainerOffset,
    bottomX: innerWidth,
    unitMeasure: "px",
  });

  const clipPathContainerMiddle = getClipPath({
    top: -1000,
    topX: 0,
    bottom: bottomMiddle - clipPathContainerOffset,
    bottomX: innerWidth,
    unitMeasure: "px",
  });

  const clipPathContainerEnd = getClipPath({
    top: -1000,
    topX: 0,
    bottom: bottomEnd - clipPathContainerOffset,
    bottomX: innerWidth,
    unitMeasure: "px",
  });

  return [
    clipPathContainerStart,
    clipPathContainerMiddle,
    clipPathContainerEnd,
  ];
};
