import type { NavCategoryProps } from "../Nav.types";
import type { NavCallOutProps } from "~components/NavCallOut";
import type { NavListItemProps } from "~components/NavListItem";
import type { StoryblokBlok } from "~types/storyblok.types";

export type FlattenedSubCategoriesShape = Array<{
  items: Array<StoryblokBlok & NavListItemProps>;
  title: string;
  callOut?: NavCallOutProps;
}>;

/**
 * Given a nav navCategories object, containing an array of sub-navCategories,
 * return a flat array containing children of all sub-navCategories
 */
export function flattenNavCategories(
  navCategories: Array<StoryblokBlok & NavCategoryProps>
): FlattenedSubCategoriesShape {
  return navCategories.reduce(
    (
      accumulator: FlattenedSubCategoriesShape,
      { navSubCategories, callOut }
    ) => {
      let mobileCallOut = callOut ? callOut[0] : undefined;
      navSubCategories?.forEach(({ title: subCategoryTitle, items }) => {
        accumulator.push({
          title: subCategoryTitle,
          items,
          callOut: mobileCallOut,
        });
        // Push callOut only at the first iteration
        if (mobileCallOut) mobileCallOut = undefined;
      });

      return accumulator;
    },
    []
  );
}
