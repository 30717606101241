import type { Polygon } from "./types";

export const PORTAL_POLYGONS: {
  start: Polygon;
  middle: Polygon;
  end: Polygon;
} = {
  start:
    "polygon(219.335px 10.2052px, 219.335px 10.2052px, 221.598989px 8.32258962px, 223.982072px 6.62041856px, 226.473323px 5.10286294px, 229.061816px 3.77409888px, 231.736625px 2.6383025px, 234.486824px 1.69964992px, 237.301487px 0.96231726px, 240.169688px 0.43048064px, 243.080501px 0.10831618px, 246.023px 1.3436791058146E-31px, 937.977px 0px, 937.977px 0px, 940.919499px 0.10831672px, 943.830312px 0.43048256px, 946.698513px 0.96232104px, 949.513176px 1.69965568px, 952.263375px 2.63831px, 954.938184px 3.77410752px, 957.526677px 5.10287176px, 960.017928px 6.62042624px, 962.401011px 8.32259448px, 964.665px 10.2052px, 1170.08px 194.205px, 1170.08px 194.205px, 1176.96747px 202.110573px, 1181.32696px 210.868224px, 1183.31009px 220.081251px, 1183.06848px 229.352952px, 1180.75375px 238.286625px, 1176.51752px 246.485568px, 1170.51141px 253.553079px, 1162.88704px 259.092456px, 1153.79603px 262.706997px, 1143.39px 264px, 40.6068px 264px, 40.6068px 264px, 30.20184522px 262.706997px, 21.11150736px 259.092456px, 13.48748214px 253.553079px, 7.48146528px 246.485568px, 3.2451525px 238.286625px, 0.93023952px 229.352952px, 0.68842206px 220.081251px, 2.67139584px 210.868224px, 7.03085658px 202.110573px, 13.9185px 194.205px, 219.335px 10.2052px)",
  middle:
    "polygon(10% 31.704%, 20.726399999999998% 27.544%, 20.858400000000003% 27.099%, 21.019199999999998% 26.6885%, 21.206400000000002% 26.316%, 21.4184% 25.984%, 21.651200000000003% 25.695%, 21.904000000000003% 25.4525%, 22.172800000000002% 25.2585%, 22.4552% 25.117%, 22.748800000000003% 25.0295%, 23.0512% 25%, 76.9488% 25%, 76.9488% 25%, 77.2512% 25.0295%, 77.5448% 25.117%, 77.8272% 25.2585%, 78.096% 25.4525%, 78.34880000000001% 25.695%, 78.58160000000001% 25.984%, 78.79360000000001% 26.316%, 78.9808% 26.6885%, 79.14160000000001% 27.099%, 79.2736% 27.544%, 89.7736% 69.3475%, 89.7736% 69.3475%, 89.9208% 70.161%, 89.9632% 70.971%, 89.90800000000002% 71.7575%, 89.76240000000001% 72.50200000000001%, 89.5352% 73.185%, 89.23360000000001% 73.787%, 88.86720000000001% 74.2885%, 88.44160000000001% 74.67099999999999%, 87.96640000000001% 74.9145%, 87.4496% 75%, 12.5512% 75%, 12.5512% 75%, 12.0336% 74.9145%, 11.5584% 74.67099999999999%, 11.1328% 74.2885%, 10.7664% 73.787%, 10.4648% 73.185%, 10.2376% 72.50200000000001%, 10.0928% 71.7575%, 10.0368% 70.971%, 10.0792% 70.161%, 10.2264% 69.3475%, 20.726399999999998% 27.544%)",
  end: "polygon(0px 40px, 0px 40px, 0.5235322px 33.5117898px, 2.0392256px 27.3569024px, 4.4647254px 21.6176926px, 7.7176768px 16.3765152px, 11.715725px 11.715725px, 16.3765152px 7.7176768px, 21.6176926px 4.4647254px, 27.3569024px 2.0392256px, 33.5117898px 0.5235322px, 40px 6.6222161283942E-31px, 1400px 0px, 1400px 0px, 1406.48787px 0.5235322px, 1412.64256px 2.0392256px, 1418.38169px 4.4647254px, 1423.62288px 7.7176768px, 1428.28375px 11.715725px, 1432.28192px 16.3765152px, 1435.53501px 21.6176926px, 1437.96064px 27.3569024px, 1439.47643px 33.5117898px, 1440px 40px, 1440px 860px, 1440px 860px, 1439.47643px 866.488113px, 1437.96064px 872.642944px, 1435.53501px 878.382131px, 1432.28192px 883.623312px, 1428.28375px 888.284125px, 1423.62288px 892.282208px, 1418.38169px 895.535199px, 1412.64256px 897.960736px, 1406.48787px 899.476457px, 1400px 900px, 40px 900px, 40px 900px, 33.5117898px 899.476457px, 27.3569024px 897.960736px, 21.6176926px 895.535199px, 16.3765152px 892.282208px, 11.715725px 888.284125px, 7.7176768px 883.623312px, 4.4647254px 878.382131px, 2.0392256px 872.642944px, 0.5235322px 866.488113px, 6.6222161283942E-31px 860px, 0px 40px)",
};
