import React from "react";

import { useStoryblokRelations } from "~hooks/useStoryblokRelations";

import { transformStoryblokEntryApiToLinkListItem } from "~utils/transformers/transformStoryblokEntryApiToLinkListItem";
import { transformStoryblokEntryNodeToLinkListItem } from "~utils/transformers/transformStoryblokEntryNodeToLinkListItem";

import { LinkList } from "~components/LinkList";

import type { LinkListProps } from "~components/LinkList";
import type { LinkListItemProps } from "~components/LinkList/LinkListItem";
import type {
  StoryblokEntry,
  StoryblokRelationsNodeType,
} from "~types/storyblok.types";

export interface RelationsCardsProps
  extends Omit<LinkListProps, "content" | "linkListItems"> {
  content?: Array<string | StoryblokEntry>;
  isAlphabetized?: boolean;
  storyblokRelationsNodeType?: StoryblokRelationsNodeType;
}

export function RelationsLinkList({
  content,
  subComponents,
  isAlphabetized,
  storyblokRelationsNodeType = "relationsLinkListNodes",
  ...rest
}: RelationsCardsProps) {
  /** Transform relations to linkListItems */
  const linkListItems: Array<LinkListItemProps> | null =
    useStoryblokRelations<LinkListItemProps>({
      content,
      transformApiEntry: transformStoryblokEntryApiToLinkListItem,
      transformGraphQlNode: transformStoryblokEntryNodeToLinkListItem,
      isAlphabetized,
      storyblokRelationsNodeType,
    });

  if (!linkListItems) return null;

  return (
    <LinkList
      linkListItems={linkListItems}
      subComponents={subComponents}
      key={rest._uid}
      {...rest}
    />
  );
}
