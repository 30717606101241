import React, { useMemo } from "react";

import Typewriter from "typewriter-effect";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { VStack } from "~components/VStack";

import type { Options as TypewriterOptions } from "typewriter-effect";
import type { ButtonLinkProps } from "~components/ButtonLink";
import type { SeoHeaderTags } from "~components/SectionHeader";
import type {
  StoryblokBlok,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

interface AnimatedTextItem extends StoryblokBlok {
  text: string;
}

export interface HeroTypewriterProps extends StoryblokBlok {
  animatedTextFragments?: Array<AnimatedTextItem & StoryblokBlok>;
  bloks: Array<StoryblokBlok & Record<string, unknown>>;
  delay?: TypewriterOptions["delay"] | undefined;
  leadingText?: string;
  prefix: string;
  prefixTag: SeoHeaderTags;
  description?: StoryblokFieldRichText;
  trailingText?: string;
  buttonLink?: Array<StoryblokBlok & ButtonLinkProps>;
}

export function HeroTypewriter({
  leadingText,
  trailingText,
  buttonLink: buttonLinkBlokArray,
  animatedTextFragments,
  prefix,
  prefixTag,
  description,
  delay = 80,
  ...rest
}: HeroTypewriterProps) {
  const [buttonLink] = buttonLinkBlokArray || [];

  const typewriterProps = useMemo<TypewriterOptions | undefined>(() => {
    if (!Array.isArray(animatedTextFragments)) {
      return undefined;
    }

    return {
      strings: animatedTextFragments.map((item) => {
        return item.text;
      }),
      autoStart: true,

      loop: true,
      delay,
    };
  }, [animatedTextFragments, delay]);

  if (animatedTextFragments) {
    return (
      <VStack maxWidth="gridSpan6" justifyContent="center" {...rest}>
        <Box>
          {prefix && (
            <Box
              {...(prefixTag && { as: prefixTag })}
              textAppearance="prefix_lg"
              color="ui_primary_base"
              marginBottom="spacing1"
            >
              {prefix}
            </Box>
          )}
          <Box
            textAppearance="h2"
            fontWeight="extrabold"
            marginBottom="spacing2"
            fontFamily="formula"
          >
            {leadingText && <div>{leadingText}</div>}
            {typewriterProps && (
              <Box
                textAppearance="h2"
                whiteSpace="nowrap"
                color="text_lowContrast"
              >
                <Typewriter options={typewriterProps} />
              </Box>
            )}
            {trailingText && <div>{trailingText}</div>}
          </Box>
          {description && (
            <StoryblokRichText
              isTextBalanced
              marginBottom="spacing2"
              maxWidth="gridSpan8"
              text={description}
            />
          )}
          {buttonLink && <ButtonLink {...buttonLink} />}
        </Box>
      </VStack>
    );
  }

  return null;
}
