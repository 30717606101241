import React, { useMemo } from "react";

import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import clsx from "clsx";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Link } from "~components/Link";
import { SvgFlagIcon } from "~components/SvgFlagIcon";
import { Tag } from "~components/Tag";

import { getCountryCodeFromJobLocation } from "./getCountryCodeFromJobLocation";
import * as styles from "./styles.css";

import type { JobSearchHit } from ".";

export interface JobSearchCollapsibleItemProps {
  job: JobSearchHit;
  className?: string;
}

export function JobSearchCollapsibleItem({
  job,
  className: userClassName,
  ...rest
}: JobSearchCollapsibleItemProps) {
  const { link, title, teamName, location } = job || {};

  const countryCode = useMemo(() => {
    return getCountryCodeFromJobLocation(location);
  }, [location]);

  if (!link) return null;

  return (
    <Link link={link} className={clsx(styles.link, userClassName)} {...rest}>
      <Box
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        marginRight="auto"
        textAppearance="body_md"
      >
        {title}
      </Box>

      {teamName && (
        <Box display={{ mobile: "none", tablet: "flex" }}>
          <Tag title={teamName} />
        </Box>
      )}
      {location && countryCode && (
        <Box display="flex" alignItems="center" fontSize="body_md">
          <SvgFlagIcon
            marginRight="spacing2"
            width="spacing3"
            countryCode={countryCode}
          />
          <Box display={{ mobile: "none", tablet: "flex" }}>{location}</Box>
        </Box>
      )}

      <IconFontAwesome size="lg" icon={faArrowRightLong} />
    </Link>
  );
}
