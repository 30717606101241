import React, { Suspense } from "react";

import { lazily } from "react-lazily";

import type { HeroTypewriterProps } from ".";

function LazyHeroTypewriter(props: HeroTypewriterProps) {
  const { HeroTypewriter } = lazily(() => {
    return import(".");
  });

  return (
    <Suspense fallback={null}>
      <HeroTypewriter {...props} />
    </Suspense>
  );
}

export default LazyHeroTypewriter;
