import React from "react";

import clsx from "clsx";

import { SectionHeader } from "~components/SectionHeader";

import { LayoutSection } from "~sections/LayoutSection";
import { RelationsCards } from "~sections/RelationsCards";

import * as styles from "./styles.css";

import type { ReactNode } from "react";
import type { SectionHeaderProps } from "~components/SectionHeader";
import type { LayoutSectionProps } from "~sections/LayoutSection";
import type { RelationsCardsProps } from "~sections/RelationsCards";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";

export interface HeroContentPageProps extends SectionHeaderProps {
  children?: ReactNode;
  layoutSectionProps?: LayoutSectionProps;
  background?: VariantBackgroundColorEnum;
  featuredContent?: Array<RelationsCardsProps>;
}

export function HeroContentPage({
  children,
  className: userClassName,
  layoutSectionProps,
  background = "background_lavender",
  author,
  authorDate,
  cover,
  coverAspectRatio,
  coverVideo,
  isCoverVideoSticky,
  logo,
  featuredContent: featuredContentArray,
  ...rest
}: HeroContentPageProps) {
  const [featuredContent] = featuredContentArray ?? [];

  return (
    <LayoutSection
      id="hero"
      background={background}
      justifyContent="center"
      className={styles.heroContentPageClassNames}
      paddingY="spacing16"
      {...layoutSectionProps}
    >
      <SectionHeader
        id="hero-content-section-header"
        className={clsx(userClassName)}
        textAppearance="h3"
        fontFamily="formula"
        maxWidth="gridSpan12"
        titleTag="h1"
        author={author}
        authorDate={authorDate}
        cover={cover}
        coverAspectRatio={coverAspectRatio}
        coverVideo={coverVideo}
        isCoverVideoSticky={isCoverVideoSticky}
        logo={logo}
        {...rest}
      />
      {featuredContent && <RelationsCards {...featuredContent} />}

      {children}
    </LayoutSection>
  );
}
