export { HeroWithPhoneMockup } from "./HeroWithPhoneMockup";
export type {
  Polygon,
  SizeArray,
  PolygonRatio,
  PolygonResponsiveRatiosInput,
  PolygonResponsiveRatiosOutput,
} from "./types";
export * as styles from "./styles.css";
export { PORTAL_POLYGONS } from "./paths";
export {
  animateLeftUi,
  animateRightUi,
  animateStepperDetails,
  animateHeroBg,
  animateHero,
  animateHeroHeader,
} from "./animationUtils";
export {
  PolygonSizeOnWindowPercentageTypes,
  PORTAL_CONFIG,
  type CLIP_PATH_CONFIG_TYPE,
  type PORTAL_CONFIG_TYPE,
  getClipPath,
  getExtremes,
  centerPolygon,
  resizePolygon as scalePolygon,
  generateSmoothPath,
  equalizePolygonPoints,
  getResponsivePolygon,
  adaptPolygonToPage,
  adaptPolygonsToPage,
  shouldResize,
  getContainerKeyframes,
} from "./portalUtils";
