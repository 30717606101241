import { useMemo } from "react";

import { parseStoryblokEntry } from "~utils/storyblok/parseStoryblokEntry";

import type { GlobalPageData } from "~types/global.types";
import type { StoryblokEntryWithStringifiedContent } from "~types/storyblok.types";

export interface UseLayoutStoryblokDataArgs {
  data: GlobalPageData;
}

export function useLayoutStoryblokData({ data }: UseLayoutStoryblokDataArgs) {
  const { alternates } = data.story || {};

  const story = useMemo(() => {
    if (data.story) {
      return parseStoryblokEntry(
        "story",
        data.story as StoryblokEntryWithStringifiedContent
      );
    }

    return undefined;
  }, [data.story]);

  const header = useMemo(() => {
    return parseStoryblokEntry("header", data.header);
  }, [data.header]);

  // banner is optional
  const banner = useMemo(() => {
    return data.banner && parseStoryblokEntry("banner", data.banner);
  }, [data.banner]);

  const footer = useMemo(() => {
    return parseStoryblokEntry("footer", data.footer);
  }, [data.footer]);

  return { story, header, banner, footer, alternates };
}
