import React, { createContext } from "react";

import clsx from "clsx";

import { recipeMaxWidth } from "~styles/common/recipeMaxWidth.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { DevLayoutGrid } from "~components/DevLayoutGrid";

// import { DevLayoutGrid } from "~components/DevLayoutGrid";
import { AnimationUserNetworkGrid1 } from "~animations/network_grid/AnimationUserNetworkGrid1/AnimationUserNetworkGrid1";
import { AnimationUserNetworkGrid2 } from "~animations/network_grid/AnimationUserNetworkGrid1/AnimationUserNetworkGrid2";

import { getSectionStyles, removeTopBorderClassName } from "./styles.css";

import type { CSSProperties, ElementType } from "react";
import type { BoxProps } from "~components/Box";
import type { ContentListItemsProps } from "~components/ContentListItems";
import type { VariantVMaxWidthEnum } from "~styles/common/recipeMaxWidth.css";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

type BoxPropsMinusForbidden = Omit<
  BoxProps,
  "background" | "color" | "maxWidth"
>;

const BackgroundUserNetworkMap: Record<string, ElementType> = {
  userNetworkWide1: AnimationUserNetworkGrid1,
  userNetworkWide2: AnimationUserNetworkGrid2,
} as const;

type BackgroundUserNetworkEnum = keyof typeof BackgroundUserNetworkMap;

export interface LayoutSectionProps extends BoxPropsMinusForbidden {
  alignItems?: GetSprinklesArgs["alignItems"];
  background?: VariantBackgroundColorEnum;
  backgroundUserNetwork?: BackgroundUserNetworkEnum;
  className?: string;
  classNameInner?: string;
  gap?: GetSprinklesArgs["gap"];
  id?: string;
  isBorderRounded?: boolean;
  isGrid?: boolean;
  isPinnedOnScroll?: boolean;
  isWidthConstrained?: boolean;
  maxWidth?: VariantVMaxWidthEnum;
  style?: CSSProperties;
  paginationItems?: ContentListItemsProps["items"];
  sectionId?: string;
  bottomPadding?: GetSprinklesArgs["paddingY"];
}

export const ThemeContext =
  createContext<VariantBackgroundColorEnum>("background_white");

export function LayoutSection({
  as = "section",
  background: userBackground,
  backgroundUserNetwork,
  children,
  className: userClassName,
  classNameInner: userClassNameInner,
  display = "flex",
  flexDirection = "column",
  id,
  isBorderRounded,
  borderTop,
  borderBottom,
  maxWidth = "gridSpan12",
  paddingY: paddingTop = "sectionPaddingY",
  paddingBottom = paddingTop,
  /** This was previously set in the CMS, but is being trialed as a hard-coded value */
  // eslint-disable-next-line unused-imports/no-unused-vars
  rowGap = "gutterWidth",
  sectionId,
  ...rest
}: LayoutSectionProps) {
  const BackgroundUserNetworkComponent =
    (backgroundUserNetwork &&
      BackgroundUserNetworkMap[backgroundUserNetwork]) ||
    undefined;

  const background = userBackground || "background_white";

  return (
    <ThemeContext.Provider value={background}>
      <Box
        id={sectionId}
        as={as}
        borderTop={borderTop}
        borderBottom={borderBottom}
        overflow="-moz-hidden-unscrollable"
        className={clsx(
          getSectionStyles({
            isBorderRounded,
            background,
          }),
          userClassName,
          borderTop === "none" && removeTopBorderClassName
        )}
      >
        {process.env.GATSBY_ENV !== "production" && <DevLayoutGrid />}

        {BackgroundUserNetworkComponent && <BackgroundUserNetworkComponent />}

        <Box
          columnGap="gutterWidth"
          data-motion={ANIMATED.secondary}
          display={display}
          data-testid="LayoutSection_inner"
          flexDirection={flexDirection}
          id={id}
          isolation={BackgroundUserNetworkComponent ? "isolate" : undefined}
          rowGap={rowGap}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          className={clsx(recipeMaxWidth({ maxWidth }), userClassNameInner)}
          {...rest}
        >
          {children}
        </Box>
      </Box>
    </ThemeContext.Provider>
  );
}
