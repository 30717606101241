import React from "react";

import { faAngleDown } from "@fortawesome/sharp-regular-svg-icons/faAngleDown";
import {
  Item as RadixNavMenuItem,
  Trigger as RadixNavMenuTrigger,
} from "@radix-ui/react-navigation-menu";

import { Button } from "~components/Button";

import { NavDesktopSubCategory } from "./_NavDesktopSubCategory";

import type { NavCategoryProps } from "../Nav.types";

export function NavDesktopCategory({
  title,
  navSubCategories,
  _uid,
  callOut,
  isActive,
}: NavCategoryProps) {
  const navCallOut = callOut ? callOut[0] : undefined;

  return (
    <RadixNavMenuItem value={_uid}>
      <RadixNavMenuTrigger asChild>
        <Button
          appearance="uiLink"
          iconRight={faAngleDown}
          paddingX="spacing1.5"
          paddingY="spacing2"
          margin="none"
        >
          {title}
        </Button>
      </RadixNavMenuTrigger>
      <NavDesktopSubCategory
        id={`category_${title}`}
        navSubCategories={navSubCategories}
        navCallOut={navCallOut}
        isActive={isActive}
      />
    </RadixNavMenuItem>
  );
}
