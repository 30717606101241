import * as React from "react";

import { faAngleDown } from "@fortawesome/sharp-regular-svg-icons/faAngleDown";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { IconBrand } from "~components/IconBrand";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { SvgFlagIcon } from "~components/SvgFlagIcon";

import { getUniqueCountryCodesFromJobList } from "./getUniqueCountryCodesFromJobList";

import type { JobSearchHit } from ".";
import type { IconBrandProps } from "~components/IconBrand";

export interface JobSearchCollapsibleTriggerProps
  extends React.HTMLProps<HTMLButtonElement> {
  jobList: Array<JobSearchHit>;
  title: string;
}

/**
 * Map of pre-agreed departments configured in
 * greenhouse to props for `IconBrand` component
 */

const DEPARTMENT_ICON_MAP: Record<string, IconBrandProps["icon"]> = {
  "Client Ops": "support",
  Commercial: "commercial",
  Design: "design",
  Engineering: "engineering",
  Finance: "finance",
  "Legal & Compliance": "legal_compliance",
  Marketing: "marketing",
  People: "people",
  Product: "product",
  Sales: "commercial",
  "Strategy & Operations": "strategy",
  Talent: "talent",
  // fallback to careers "ladder" icon
  default: "careers",
};

export function JobSearchCollapsibleTrigger({
  title,
  jobList,
  className: userClassName,
  ...rest
}: JobSearchCollapsibleTriggerProps) {
  const titleWithJobCount = `${title} (${jobList?.length})`;

  const departmentIcon = React.useMemo(() => {
    return (title && DEPARTMENT_ICON_MAP[title]) || DEPARTMENT_ICON_MAP.default;
  }, [title]);

  const uniqueCountriesInDepartment = React.useMemo(() => {
    return getUniqueCountryCodesFromJobList(jobList);
  }, [jobList]);

  const hasCountries = Array.isArray(uniqueCountriesInDepartment);

  const expandIconClassnames = getSprinkles({ marginLeft: "auto" });

  return (
    <button
      {...rest}
      type="button"
      className={clsx(
        userClassName,
        getSprinkles({
          padding: "spacing3",
          alignItems: "center",
          display: "flex",
          gap: "spacing1.5",
        })
      )}
    >
      {departmentIcon && (
        <IconBrand
          width="spacing4"
          color="text_highContrast"
          height="spacing4"
          icon={departmentIcon}
        />
      )}
      <Box textAppearance="body_lg" fontWeight="bold" margin="none">
        {titleWithJobCount}
      </Box>
      {hasCountries &&
        uniqueCountriesInDepartment.map((countryCode) => {
          return (
            <SvgFlagIcon
              width="spacing3"
              countryCode={countryCode}
              key={countryCode}
            />
          );
        })}
      <IconFontAwesome
        icon={faAngleDown}
        size="lg"
        className={expandIconClassnames}
      />
    </button>
  );
}
